import React from "react"
import FormLayout from "common/components/templates/FormLayout"
import Title from "common/components/atoms/Title"
import Text from "common/components/atoms/Text"
import { useTranslation } from "react-i18next"

const PasswordSent = () => {
  const { t } = useTranslation()

  return (
    <FormLayout>
      <Title order={5} color="purple" fw="bold">
        {t("titles.emailSent")}
      </Title>
      <Text ta="center" fw="bold">
        {t("common.passwordSentDescription")}
      </Text>
    </FormLayout>
  )
}

export default PasswordSent
