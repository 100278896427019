import { create } from "zustand";

interface ErrorState {
  error: string;
  setError: (errorMessage: string, variant?: "error" | "success") => void;
  clearError: () => void;
  variant: "error" | "success";
}

const useError = create<ErrorState>((set) => ({
  error: "",
  variant: "error",

  setError: (errorMessage, variant) => {
    set({ error: errorMessage, variant: variant });
  },

  clearError() {
    set({ error: "" });
  },
}));

export default useError;
