import { create } from "zustand"

interface AuthState {
  accessToken: string | null
  refreshToken: string | null
  isAuthenticated: boolean
  userLogin: UserLogin
  resetPassword: resetPasswordProps
  userRegister: RegisterProps
  enUserRegister: ENRegisterProps
  forgetPassword: string
  fieldErrors: FieldError | null
  loading: boolean
  userType: string
  selectedFile: File | undefined
  setUserLogin: (data: UserLogin) => void
  setUserRegister: (data: RegisterProps) => void
  setENUserRegister: (data: ENRegisterProps) => void
  setResetPassword: (data: resetPasswordProps) => void
  setIsAuthenticated: (data: boolean) => void
  setforgetPassword: (data: string) => void
  login: (accessToken: string, refreshToken: string) => void
  logout: () => void
  setFieldError: (data: FieldError | null) => void
  setLoading: (status: boolean) => void
  setUserType: (type: string) => void
  setSelectedFile: (selectedFile: File) => void
  clearUserRegisterData: () => void
  locale: "ja" | "en"
}

const useAuthStore = create<AuthState>((set) => ({
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  forgetPassword: "",
  text: null,
  userLogin: {
    email: "",
    password: "",
  },
  userRegister: {
    familyName: "",
    firstName: "",
    familyNameKana: "",
    firstNameKana: "",
    province: "",
    buildingName: "",
    address: "",
    zipcode: "",
    city: "",
    email: "",
    password: "",
    mainChannelName: "",
    youtubeChannelUrl: [],
    youtubeShortCount: 0,
    agency: false,
    isCompany: false,
    companyName: "",
    averageViewCounts: 0,
    averageMonthlyView: 0,
    screenShot: "",
  },
  enUserRegister: {
    fullName: "",
    state: "",
    apartment: "",
    country: "",
    address: "",
    zipcode: "",
    city: "",
    email: "",
    password: "",
    are_you_corporation: false,
    youtubeChannelUrl: [],
    companyName: "",
    isCompany: false,
    mainChannelName: "",
    youtubeShortCount: 0,
    agency: false,
    averageViewCounts: 0,
    screenShot: "",
    inviteName: null,
    isSponsorShip: false,
  },
  resetPassword: {
    password: "",
    confirmPassword: "",
  },
  fieldErrors: null,
  loading: false,
  userType: "admin",
  selectedFile: undefined,

  setUserLogin: (data) => {
    set({ userLogin: { ...data } })
  },

  setUserRegister: (data) => {
    set({ userRegister: { ...data } })
  },

  setENUserRegister: (data) => {
    set({ enUserRegister: { ...data } })
  },

  setIsAuthenticated: (data) => {
    set({ isAuthenticated: data })
  },

  login: (accessToken, refreshToken) => {
    set({
      accessToken,
      refreshToken,
      isAuthenticated: true,
    })
  },

  logout: () => {
    set({
      accessToken: null,
      refreshToken: null,
      isAuthenticated: false,
    })
  },

  setforgetPassword: (data) => {
    set({ forgetPassword: data })
  },

  setResetPassword: (data) => {
    set({ resetPassword: { ...data } })
  },

  setFieldError: (data) => {
    set({ fieldErrors: data ? { ...data } : null })
  },

  setLoading: (status) => {
    set({ loading: status })
  },

  setUserType: (type) => {
    set({ userType: type })
  },

  setSelectedFile: (file) => {
    set({ selectedFile: file })
  },

  clearUserRegisterData: () => {
    set({
      userRegister: {
        familyName: "",
        firstName: "",
        familyNameKana: "",
        firstNameKana: "",
        province: "",
        buildingName: "",
        address: "",
        zipcode: "",
        city: "",
        email: "",
        password: "",
        mainChannelName: "",
        youtubeChannelUrl: [],
        youtubeShortCount: 0,
        agency: false,
        isCompany: false,
        companyName: "",
        averageViewCounts: 0,
        screenShot: "",
      },
    })
  },
  locale: "ja",
}))

export default useAuthStore
