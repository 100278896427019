import React, { useState, useEffect } from "react"
import InfluencerContract from "pages/en/InfluencerContract"
import UsageDocumentInfo from "pages/en/UsageDocumentInfo"
import useAuthStore from "store/authStore"
import FormLayout from "common/components/templates/FormLayout"
import RegistrationComplete from "pages/en/RegistrationComplete"

const components = [UsageDocumentInfo, InfluencerContract, RegistrationComplete]
const stepTitles = ["Our Service", "Examination form", "Application completed"]

const Register = () => {
  const [currentPage, setNextPage] = useState(0)
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)
  const CurrentComponent = components[currentPage] || null
  const isEnglish = window.location.href.includes("/en/")

  useEffect(() => {
    if (isAuthenticated) window.location.href = "/en/"
  }, [isAuthenticated])

  useEffect(() => {
    if (isEnglish) {
      document.title = "Orinas Contract Tool"
    } else {
      document.title = "オリナス契約ツール"
    }
  }, [isEnglish])

  const onProceed = async () => {
    if (currentPage < components.length - 1) {
      setNextPage((currentPage) => currentPage + 1)
    }
  }

  const onPrevious = () => {
    setNextPage((currentPage) => currentPage - 1)
  }

  return (
    <FormLayout
      stepperVisible
      totalSteps={3}
      currentStep={currentPage + 1}
      stepTitle={stepTitles[currentPage]}
    >
      <CurrentComponent onProceed={onProceed} onPrevious={onPrevious} />
    </FormLayout>
  )
}

export default Register
