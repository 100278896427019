import axios, {
  AxiosInstance,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios"
import { getCookies, removeCookies, setCookies } from "../cookies"
import { refreshAccessToken } from "services/auth"
import { getSubdomain } from "../helper"

const isForeignUser = window.location.pathname.includes("en")

const instance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

instance.interceptors.request.use(
  (config) => {
    const accessToken = getCookies("accessToken")
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalRequest: AxiosRequestConfig | any = error.config

    if (
      error.response &&
      error.response?.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true

      const refreshToken: string | undefined = getCookies("refreshToken")

      if (!refreshToken) {
        removeCookies("accessToken")
        removeCookies("refreshToken")
        isForeignUser
          ? (window.location.href = "/en/login")
          : (window.location.href = "/login")
        return Promise.reject(error)
      }

      try {
        const userType = getSubdomain(window.location.hostname)
        const tokenResponse: AuthTokens = await refreshAccessToken(
          { refreshToken },
          userType
        )

        setCookies("accessToken", tokenResponse.accessToken)

        originalRequest.headers[
          "Authorization"
        ] = `Bearer ${tokenResponse.accessToken}`

        return instance(originalRequest)
      } catch (refreshError) {
        removeCookies("accessToken")
        removeCookies("refreshToken")
        isForeignUser
          ? (window.location.href = "/en/login")
          : (window.location.href = "/login")

        return Promise.reject(refreshError)
      }
    }

    return Promise.reject(error)
  }
)

export default instance
