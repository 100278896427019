import useSWRMutation from "swr/mutation"
import { useAPI } from "../useAPI"

const useLogin = () => {
  const { put } = useAPI()

  const sendLoginRequest = async (url: string, { arg }: { arg: UserLogin }) => {
    const response: AuthTokens = await put(url, arg)
    return response
  }

  const { trigger, isMutating, error } = useSWRMutation(
    `/influencer/en/auth/login`,
    sendLoginRequest
  )

  return {
    onLogin: trigger,
    isLoading: isMutating,
    error,
  }
}

export default useLogin
