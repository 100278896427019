import React from "react"
import SideBar from "common/components/SideBar"
import AppShell from "common/components/atoms/Appshell/Appshell"
import Navbar from "common/components/atoms/Navbar"
import { Navigate } from "react-router-dom"
import { getCookies } from "utils/cookies"
import useAuthStore from "store/authStore"
import NavbarEn from "common/components/atoms/NavbarEn"

interface ProtectedRoutesProps {
  children: JSX.Element
}

const ProtectedRoutes = ({ children }: ProtectedRoutesProps) => {
  const isAuthenticated = getCookies("accessToken")
  const userType = useAuthStore((state) => state.userType)
  const isForeignUser = window.location.pathname.includes("en")

  return isAuthenticated ? (
    <AppShell
      SidebarComponent={userType === "admin" ? <SideBar /> : <></>}
      NavbarComponent={isForeignUser ? <NavbarEn /> : <Navbar />}
    >
      {children}
    </AppShell>
  ) : (
    <Navigate to={isForeignUser ? "/en/login" : "/login"} />
  )
}

export default ProtectedRoutes
