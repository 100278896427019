import React, { FC } from "react";
import styles from "./Dropdown.module.scss";
import Text from "../Text";

type Option = {
  value: string;
  label: string;
};

interface InputProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  id: string;
  inputProps?: React.SelectHTMLAttributes<HTMLSelectElement>;
  error?: string;
  options?: Option[];
  variant?: "sm" | "md";
}

const Dropdown: FC<InputProps> = ({
  label,
  id,
  inputProps,
  error,
  options,
  variant = "md",
  ...props
}) => {
  return (
    <div className={styles.inputWrapper}>
      <label htmlFor={id} className={`${styles.label}`}>
        {label}
      </label>
      <select
        className={`${styles.select} ${styles[variant]}`}
        {...inputProps}
        {...props}
      >
        {options?.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && (
        <Text sz="xxs" color="red">
          {error}
        </Text>
      )}
    </div>
  );
};

export default Dropdown;
