import React, { FC, InputHTMLAttributes } from "react";
import styles from "./Input.module.scss";
import Text from "../Text";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  id: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  error?: string;
}

const Input: FC<InputProps> = ({ label, id, inputProps, error, ...props }) => {
  return (
    <div className={styles.inputWrapper}>
      <label htmlFor={id} className={`${styles.label}`}>
        {label}
      </label>
      <input className={`${styles.input}`} {...inputProps} {...props} />
      {error && (
        <Text sz="xxs" color="red">
          {error}
        </Text>
      )}
    </div>
  );
};

export default Input;
