import React, { useEffect } from "react"
import ContractorDetailsCard from "common/components/templates/ContractorDetailsCard"
import { useParams } from "react-router-dom"
import useContractorDetails from "common/hooks/en_hooks/useContractorDetails"
import useError from "common/hooks/useErrors"
import useInfluencersStoreEn from "store/influencersStoreEn"
import { getErrorMessage } from "utils/helper"
import { useNavigate } from "react-router-dom"
import ContractorDetailsCardEn from "common/components/templates/ContractorDetailsCardEn"

const ContractorDetailsEn = () => {
  const params = useParams()
  const id = params?.id
  const navigate = useNavigate()
  const { contractorDetails, error, fetchContractorDetails } =
    useContractorDetails(id)
  const setError = useError((state) => state.setError)
  const setCurrentInfluencer = useInfluencersStoreEn(
    (state) => state.setCurrentInfluencer
  )
  const clearCurrentInfluencer = useInfluencersStoreEn(
    (state) => state.clearCurrentInfluencer
  )

  useEffect(() => {
    if (error) {
      setError(getErrorMessage(error.subStatusCode))
      navigate("/")
    }
  }, [error, navigate, setError])

  useEffect(() => {
    if (contractorDetails) {
      setCurrentInfluencer(contractorDetails.data.influencer)
    }
    return () => clearCurrentInfluencer()
  }, [clearCurrentInfluencer, contractorDetails, setCurrentInfluencer])
  // return <h1> Contractor details</h1>

  return (
    <ContractorDetailsCardEn fetchContractorDetails={fetchContractorDetails} />
  )
}

export default ContractorDetailsEn
