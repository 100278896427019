import axios, { isAxiosError } from "axios"

export const refreshAccessToken = async (
  payload: RefreshTokenPayload,
  userType: string
) => {
  const isForeignUser = window.location.pathname.includes("en")

  try {
    const response = await axios.post(
      isForeignUser
        ? `${process.env.REACT_APP_API_BASE_URL}influencer/en/auth/refresh`
        : `${process.env.REACT_APP_API_BASE_URL}/${userType}/auth/refresh`,
      payload
    )
    return Promise.resolve(response.data)
  } catch (err: unknown) {
    if (isAxiosError(err)) {
      return Promise.reject(err.response?.data)
    } else return Promise.reject(err)
  }
}
