import useSWR from "swr";
import { useAPI } from "./useAPI";
import useAuthStore from "store/authStore";
import { formatDateToYYYYMMDD } from "utils/helper";

const useInfluencers = (
  nextOffset: number = 0,
  isCompany: string | null = null,
  status: string = "All",
  search: string = "",
  startDate: string = "",
  endDate: string = ""
) => {
  const userType = useAuthStore((state) => state.userType);
  const { get } = useAPI();
  if (startDate && !endDate) {
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    endDate = formatDateToYYYYMMDD(nextDay, "-");
  }
  const {
    data: influencersData,
    error,
    mutate,
    isLoading,
  } = useSWR<InfluencersList>(
    userType === "admin"
      ? `/admin/influencer/?limit=50&offset=${
          nextOffset || 0
        }&isCompany=${isCompany}&status=${status}&search=${search}&startDate=${startDate}&endDate=${endDate}`
      : "",
    (url: string) => get(url),
    {
      revalidateOnFocus: false,
    }
  );

  return {
    influencersData,
    isLoading,
    error,
    mutateInfluencers: mutate,
  };
};

export default useInfluencers;
