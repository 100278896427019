import Title from "common/components/atoms/Title"
import Button from "common/components/atoms/Button"
import styles from "./UsageDocumentInfo.module.scss"

interface DocumentInfoProps {
  onProceed: () => void
  onPrevious: () => void
}

const UsageDocumentInfo = ({ onProceed }: DocumentInfoProps) => {
  return (
    <>
      <Title order={5} color="purple" fw="bold" ta="center">
        BGM Monetization Service
      </Title>
      <div className={styles.description}>
        <li>Get started completely free of charge !</li>
        <li>
          Earn sound revenue in addition to advertising revenue !
        </li>
        <li>Used by more than 100 famous YouTuber !</li>
      </div>
      <div className={styles.buttonsContainer}>
        <a
          href="../assets/document/contract-doc-en.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            variant="purple"
            icon="../assets/icons/open-tab.svg"
            iconPosition="right"
          >
            Check the details
          </Button>
        </a>
        <Button variant="yellow" onClick={onProceed}>
          Proceed account check
        </Button>
      </div>
    </>
  )
}

export default UsageDocumentInfo
