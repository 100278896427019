import React from "react"
import Title from "common/components/atoms/Title"
import Input from "common/components/atoms/Input"
import Button from "common/components/atoms/Button"
import Link from "common/components/atoms/Link"
import FormLayout from "common/components/templates/FormLayout"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { emailRegex } from "utils/constant"
import { useNavigate } from "react-router-dom"
import useForgotPassword from "common/hooks/en_hooks/useForgotPassword"
import useError from "common/hooks/useErrors"
import { getErrorMessage } from "utils/helper"
import Loader from "common/components/atoms/Loader"
import { useTranslation } from "react-i18next"

const ForgetPasswordEn = () => {
  const navigate = useNavigate()
  const setError = useError((state) => state.setError)
  const { t } = useTranslation()

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(getErrorMessage("1006"))
      .required(getErrorMessage("1005"))
      .matches(emailRegex, getErrorMessage("1006")),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { onForgotPassword, isLoading } = useForgotPassword()

  const onSendEmail = ({ email }: ForgotPasswordPayload) => {
    onForgotPassword({ email: email })
      .then((response) => {
        if (response.success) {
          navigate("/en/password-sent")
        }
      })
      .catch((error: ResponseError) =>
        setError(getErrorMessage(error.subStatusCode))
      )
  }

  return (
    <FormLayout>
      <Title order={5} color="purple" fw="bold">
        Forgot Password
      </Title>
      <Input
        label={"Email"}
        placeholder={"Please enter your email"}
        id={"email"}
        inputProps={{ ...register("email") }}
        type="email"
        error={errors.email?.message}
      />
      <Button variant="yellow" onClick={handleSubmit(onSendEmail)}>
        {isLoading && <Loader />} Send Email
      </Button>
      <Link to="/en/login">{"Back to login page"}</Link>
    </FormLayout>
  )
}

export default ForgetPasswordEn
