import React from "react"
import styles from "./SideBar.module.scss"
import useAuthStore from "store/authStore"
import { useNavigate } from "react-router-dom"

const SideBar = () => {
  const userType = useAuthStore((state) => state.userType)
  const navigate = useNavigate()

  return (
    <aside className={styles.aside}>
      <header>
        <h2 onClick={() => navigate("/")}>オリナス契約ツール</h2>
        {userType === "admin" && <h3>管理者ページ</h3>}
      </header>
      <menu>
        {userType === "admin" ? (
          <>
            <li
              onClick={() => navigate("/dashboard")}
              className={styles.linkItem}
            >
              契約者一覧
            </li>
            <li
              onClick={() => navigate("/dashboardnew")}
              className={styles.linkItem}
            >
              海外インフルエンサー
            </li>
            <li
              onClick={() => navigate("/add-influencer-ja")}
              className={styles.linkItem}
            >
              インフルエンサーの登録(日本)
            </li>
            <li
              onClick={() => navigate("/add-influencer-en")}
              className={styles.linkItem}
            >
              インフルエンサーの登録(海外)
            </li>
          </>
        ) : (
          <></>
        )}
      </menu>
    </aside>
  )
}

export default SideBar
