import React from "react"
import Button from "common/components/atoms/Button"
import Input from "common/components/atoms/Input"
import FormLayout from "common/components/templates/FormLayout"
import Title from "common/components/atoms/Title"
import Text from "common/components/atoms/Text"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { getErrorMessage } from "utils/helper"
import styles from "../InfluencerContract/InfluencerContract.module.scss"
import { emailRegex } from "utils/constant"
import useEditProfile from "common/hooks/en_hooks/useEditProfile"
import useError from "common/hooks/useErrors"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Loader from "common/components/atoms/Loader"
import useInfluencersStoreEn from "store/influencersStoreEn"

const EditProfile = () => {
  const { onUpdateProfile, isLoading } = useEditProfile()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const setError = useError((state) => state.setError)
  const currentInfluencer = useInfluencersStoreEn(
    (state) => state.currentInfluencer
  )

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(getErrorMessage("1006"))
      .required(getErrorMessage("1005"))
      .matches(emailRegex, getErrorMessage("1006")),
    mainChannelName: yup.string().required("必須項目です"),
    youtubeShortCount: yup.number().required(),
    agency: yup.boolean().required(),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      agency: currentInfluencer?.agency || false,
      email: currentInfluencer?.email || "",
      mainChannelName: currentInfluencer?.mainChannelName || "",
      youtubeShortCount: currentInfluencer?.youtubeShortCount || 0,
    },
  })

  const handleProfileUpdate = (data: Partial<ENRegisterProps>) => {
    onUpdateProfile({
      ...currentInfluencer,
      companyName: currentInfluencer?.companyName || "",
      ...data,
    })
      .then(() => {
        setError("", "success")
        navigate("/en/dashboard")
      })
      .catch(() => setError(getErrorMessage("default")))
  }

  return (
    <FormLayout>
      <Title order={5} color="purple" fw="bold">
        Edit Profile
      </Title>
      <Input
        label={"Email"}
        placeholder={"Please enter your email"}
        id="email"
        inputProps={{ ...register("email") }}
        type="email"
        error={errors.email?.message}
      />
      <Input
        label={"Channel Name"}
        placeholder={"Please enter the name of your main channel"}
        id={"mainChannelName"}
        inputProps={{ ...register("mainChannelName") }}
        error={errors.mainChannelName?.message}
      />
      <Input
        label={"Frequency of posting YouTube shorts"}
        placeholder={"Please enter the frequency of posting YouTube Shorts"}
        id="youtubeShortCount"
        inputProps={{ ...register("youtubeShortCount") }}
        error={errors.youtubeShortCount?.message}
      />
      <div className={styles.agencyCheckBox}>
        <Text fw="bold">{"Do you belong to an agency?"}</Text>
        <input type="checkbox" {...register("agency")} />
      </div>
      <Button variant="yellow" onClick={handleSubmit(handleProfileUpdate)}>
        {isLoading && <Loader />}
        Edit
      </Button>
      <Button variant="grey" onClick={() => navigate("/en/dashboard")}>
        Cancel
      </Button>
    </FormLayout>
  )
}

export default EditProfile
