import React from "react"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { passwordRegex } from "utils/constant"
import { getErrorMessage } from "utils/helper"
import FormLayout from "common/components/templates/FormLayout"
import Title from "common/components/atoms/Title"
import Input from "common/components/atoms/Input"
import Button from "common/components/atoms/Button"
import useChangePassword from "common/hooks/en_hooks/useChangePassword"
import useError from "common/hooks/useErrors"
import Loader from "common/components/atoms/Loader"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const ChangePassword = () => {
  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .required(getErrorMessage("1008"))
      .matches(passwordRegex, getErrorMessage("1003")),
    newPassword: yup
      .string()
      .required(getErrorMessage("1008"))
      .matches(passwordRegex, getErrorMessage("1003")),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { onChangePassword, isLoading } = useChangePassword()
  const setError = useError((state) => state.setError)
  const navigate = useNavigate()

  const handlePasswordChange = (data: ChangePasswordProps) => {
    onChangePassword(data)
      .then((response) => {
        setError("Password has been changed", "success")
        navigate("/en/dashboard")
      })
      .catch((err: ResponseError) =>
        setError(getErrorMessage(err?.subStatusCode))
      )
  }

  return (
    <FormLayout>
      <Title order={5} color="purple" fw="bold">
        Change Password
      </Title>
      <Input
        label={"Current Password"}
        placeholder={"Please enter your current password"}
        id="currentPassword"
        inputProps={{ ...register("currentPassword") }}
        type="password"
        error={errors.currentPassword?.message}
      />
      <Input
        label={"New password"}
        placeholder={"Please enter your new password"}
        id="newPassword"
        inputProps={{ ...register("newPassword") }}
        type="password"
        error={errors.newPassword?.message}
      />
      <Button variant="yellow" onClick={handleSubmit(handlePasswordChange)}>
        {isLoading && <Loader />}
        Change
      </Button>
      <Button variant="grey" onClick={() => navigate("/en/dashboard")}>
        Cancel
      </Button>
    </FormLayout>
  )
}

export default ChangePassword
