import useSWRMutation from "swr/mutation";
import { useAPI } from "./useAPI";
import useInfluencersStore from "store/influencersStore";

interface ContractStatusType {
  status: string;
}

const useContractStatus = () => {
  const influencerId = useInfluencersStore(
    (state) => state.currentInfluencer?._id
  );
  const { put } = useAPI();

  const updateContractStatus = async (
    url: string,
    { arg }: { arg: ContractStatusType }
  ) => {
    const response: AuthTokens = await put(`${url}/${arg.status}`, {
      InfluencersId: influencerId,
    });
    return response;
  };

  const { trigger, isMutating, error } = useSWRMutation(
    `/admin/influencer/${influencerId}`,
    updateContractStatus
  );

  const updateContractData = async (
    url: string,
    { arg }: { arg: EditUserInformation }
  ) => {
    const response: AuthTokens = await put(url, arg);
    return response;
  };

  const {
    trigger: updateContract,
    isMutating: updating,
    error: updateError,
  } = useSWRMutation(
    `/admin/influencer/${influencerId}/update`,
    updateContractData
  );

  return {
    onStatusUpdate: trigger,
    updateContract,
    updating,
    isLoading: isMutating,
    error,
    updateError,
  };
};

export default useContractStatus;
