import React, { useState, useEffect } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "common/components/atoms/Table";
import useInfluencersStore from "store/influencersStore";
import Text from "common/components/atoms/Text";
import styles from "./InfluencersTableSection.module.scss";
import { useNavigate } from "react-router-dom";
import { contractStatus } from "utils/constant";
import useInfluencers from "common/hooks/useInfluencers";
import useError from "common/hooks/useErrors";
import { debounce, formatDateToYYYYMMDD, getErrorMessage } from "utils/helper";
import Dropdown from "common/components/atoms/Dropdown";
import check from "common/assets/icons/check-icon.svg";

const contractStatusAll = { All: "ステータス", ...contractStatus };

const contractStatusArray = Object.entries(contractStatusAll).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
);

const userType = [
  { value: "", label: "全て" },
  { value: "true", label: "法人" },
  { value: "false", label: "個人" },
];

const InfluencersTableSection = () => {
  const influencersData = useInfluencersStore((state) => state.influencers);
  const setInfluencersData = useInfluencersStore(
    (state) => state.setInfluencersData
  );
  const influencerList = influencersData?.docs;
  const columnHelper = createColumnHelper<InfluencerData>();
  const navigate = useNavigate();
  const setError = useError((state) => state.setError);
  const [nextOffset, setNextOffset] = useState(0);
  const [isCompany, setIsCompany] = useState<null | string>();
  const [status, setStatus] = useState("All");
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const {
    influencersData: newData,
    isLoading,
    error,
  } = useInfluencers(nextOffset, isCompany, status, search, startDate, endDate);

  useEffect(() => {
    if (error) setError(getErrorMessage("default"));
    if (newData) setInfluencersData(newData.data.influencer);
  }, [error, newData]);

  const onLoadData = (direction: "NEXT" | "PREV" = "NEXT") => {
    const limit = influencersData?.limit || 10;
    const offset =
      direction === "NEXT"
        ? ((influencersData?.nextPage ?? 1) - 1) * limit
        : ((influencersData?.prevPage ?? 1) - 1) * limit;
    setNextOffset(offset);
  };

  const columns = [
    columnHelper.accessor("isCompany", {
      header: () => "法人",
      cell: (info) =>
        info.row.original.isCompany ? (
          <img
            src={check}
            alt="left-icon"
            height={28}
            className={styles.checkMark}
          />
        ) : (
          ""
        ),
    }),
    columnHelper.accessor("firstName", {
      header: () => "名前",
      cell: (info) =>
        `${info.row.original.familyName} ${info.row.original.firstName}`,
    }),
    columnHelper.accessor("email", {
      header: () => "メールアドレス",
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("youtubeChannelUrl", {
      header: () => "登録チャンネル数",
      cell: (info) => info.renderValue()?.length,
    }),
    columnHelper.accessor("mainChannelName", {
      header: () => "メインチャンネルの名前",
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor("status", {
      header: () => "ステータス",
      cell: (info) => contractStatus[info.getValue()],
    }),
    columnHelper.accessor("updatedAt", {
      header: () => "更新日",
      cell: (info) =>
        formatDateToYYYYMMDD(new Date(info.row.original.updatedAt), "/"),
    }),
    columnHelper.accessor("createdAt", {
      header: () => "",
      cell: (info) => (
        <button
          onClick={() => navigate(`/profile/${info.row.original._id}`)}
          className={styles.tableDetailButton}
        >
          <Text fw="bold" color="purple">
            詳細
          </Text>
          <img src="/assets/icons/right-chevron.svg" alt={"details"} />
        </button>
      ),
    }),
  ];

  const debouncedSearch = debounce((value: string) => {
    setSearch(value);
    setNextOffset(0);
  }, 300);

  return (
    <>
      <div className={styles.filterContainer}>
        <div className={styles.inputBox}>
          <label className={styles.label}>検索</label>
          <input
            placeholder="フリーワード"
            onChange={(e) => debouncedSearch(e.target.value)}
            // value={search}
            className={styles.searchInput}
          />
        </div>
        <Dropdown
          options={contractStatusArray}
          label={"ステータス"}
          id={"status"}
          name="status"
          onChange={(e) => {
            setNextOffset(0);
            setStatus(e.target.value);
          }}
          variant="sm"
        />
        <Dropdown
          options={userType}
          label={""}
          id={"status"}
          name="status"
          onChange={(e) => {
            setNextOffset(0);
            setIsCompany(e.target.value);
          }}
          variant="sm"
        />
        <div className={styles.inputBox}>
          <label className={styles.label}>期間の開始日</label>
          <input
            type="date"
            max={new Date().toISOString().split("T")[0]}
            className={styles.dateBox}
            onChange={(e) => {
              setNextOffset(0);
              e.target.value
                ? setStartDate(
                    formatDateToYYYYMMDD(new Date(e.target.value), "-")
                  )
                : setStartDate("");
            }}
          />
        </div>
        <div className={styles.inputBox}>
          <label className={styles.label}>期間の終了日</label>
          <input
            type="date"
            max={new Date().toISOString().split("T")[0]}
            className={styles.dateBox}
            onChange={(e) => {
              setNextOffset(0);
              if (e.target.value) {
                const selectedDate = new Date(e.target.value);
                const nextDay = new Date(selectedDate);
                nextDay.setDate(selectedDate.getDate() + 1);
                setEndDate(formatDateToYYYYMMDD(nextDay, "-"));
              } else setEndDate("");
            }}
          />
        </div>
      </div>
      <div className={styles.influencerTable}>
        {influencerList && (
          <Table
            onFilter={() => {}}
            tableTitle="契約者一覧"
            data={influencerList}
            columns={columns}
            onNext={
              influencersData.hasNextPage ? () => onLoadData("NEXT") : undefined
            }
            onPrev={
              influencersData.hasPrevPage ? () => onLoadData("PREV") : undefined
            }
            isLoading={isLoading}
          />
        )}
      </div>
    </>
  );
};

export default InfluencersTableSection;
