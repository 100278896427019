import React from "react"
import Text from "common/components/atoms/Text"
import Title from "common/components/atoms/Title"
import Button from "common/components/atoms/Button"
import { useNavigate } from "react-router-dom"

const RegistrationComplete = () => {
  const navigate = useNavigate()

  return (
    <>
      <Title order={5} color="purple" fw="bold" ta="center" lineHeight="1">
        {`Thank you for your application.`}
      </Title>
      <Text fw="bold" sz="xxs">
        {`We will check the information you provided, so please wait for a moment. We will contact you with the results within two business days, excluding weekends and holidays.`}
      </Text>
      <Button variant="yellow" onClick={() => navigate("/en/login")}>
        Go to Login
      </Button>
    </>
  )
}

export default RegistrationComplete
