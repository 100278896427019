import React from 'react'
import styles from './Text.module.scss'
import { TextProps } from './@types'


const Text: React.FC<TextProps> = ({
  children,
  color = 'black',
  fw = 'normal',
  ta = 'left',
  sz = 'sm',
  fontFamily = 'noto-sans',
  lineHeight = '1.5',
  className = '',
  style,
  span,
}) => {
  const classes = `${styles.text} ${styles[color]} ${styles[fw]} ${styles[ta]} ${styles[sz]} ${styles[fontFamily]} ${className}`

  if (span) {
    return (
      <span className={classes} style={{ lineHeight, ...style }}>
        {children}
      </span>
    )
  }
  return (
    <p className={classes} style={{ lineHeight, ...style }}>
      {children}
    </p>
  )
}

export default Text
