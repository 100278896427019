import useSWRMutation from "swr/mutation";
import { useAPI } from "./useAPI";
import useAuthStore from "store/authStore";

const useEditProfile = () => {
  const userType = useAuthStore((state) => state.userType);
  const { put } = useAPI();

  const sendUpdateRequest = async (
    url: string,
    { arg }: { arg: Partial<RegisterProps> }
  ) => {
    const response: AuthTokens = await put(url, arg);
    return response;
  };

  const { trigger, isMutating, error } = useSWRMutation(
    `/${userType}/profile/update`,
    sendUpdateRequest
  );

  return {
    onUpdateProfile: trigger,
    isLoading: isMutating,
    error,
  };
};

export default useEditProfile;
