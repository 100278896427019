import instance from "utils/axios"
import { isAxiosError } from "axios"

export const getInfluencersCSVdata = async () => {
  try {
    const response = await instance.get(`admin/influencer/csv?status=All`)
    return Promise.resolve(response.data)
  } catch (err: unknown) {
    if (isAxiosError(err)) {
      return Promise.reject(err.response?.data)
    } else return Promise.reject(err)
  }
}

export const getEnInfluencerCSVdata = async () => {
  try {
    const response = await instance.get(`admin/influencer/en/csv?status=All`)
    return Promise.resolve(response.data)
  } catch (err: unknown) {
    if (isAxiosError(err)) {
      return Promise.reject(err.response?.data)
    } else return Promise.reject(err)
  }
}
