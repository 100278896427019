import { v4 as uuidv4 } from "uuid";
const { S3Client, PutObjectCommand } = require("@aws-sdk/client-s3");

const s3Client = new S3Client({
  endpoint: process.env.REACT_APP_AWS_STORAGE_ENDPOINT,
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  },
});

export const uploadFiletoStorage = async (file: File) => {
  try {
    const filepath = `screenshots/${uuidv4()}-${file.name}`;

    const putObjectCommand = new PutObjectCommand({
      Bucket: process.env.REACT_APP_AWS_BUCKET_ID,
      Key: `${filepath}`,
      Body: file,
      ACL: "public-read",
      "Content-Type": file.type,
    });

    const response = await s3Client.send(putObjectCommand);

    if (response.$metadata.httpStatusCode === 200) {
      return filepath;
    } else {
      throw new Error("Failed to upload file");
    }
  } catch (e) {
    console.log(e);
    throw new Error("Failed to upload file");
  }
};
