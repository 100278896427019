import React, { FC, ReactNode } from "react"
import "./Appshell.scss"

interface AppShellProps {
  SidebarComponent: ReactNode
  NavbarComponent: ReactNode
  children: ReactNode
}

const AppShell: FC<AppShellProps> = ({
  SidebarComponent,
  NavbarComponent,
  children,
}) => (
  <div className="app-shell">
    {SidebarComponent}
    <div className="content">
      {NavbarComponent}
      <main>{children}</main>
    </div>
  </div>
)

export default AppShell
