import React, { useState, useEffect } from "react";
import "./Notification.scss";

interface NotificationProps {
  message: string;
  onClose: () => void;
  timeout?: number;
  variant?: "success" | "error";
}

const Notification: React.FC<NotificationProps> = ({
  message,
  onClose,
  timeout,
  variant = "error",
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (timeout) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        onClose();
      }, timeout);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [timeout, onClose]);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    <>
      {isVisible && (
        <div className={`notification ${variant}`}>
          <p>{message}</p>
          <button className="close-button" onClick={handleClose}>
            X
          </button>
        </div>
      )}
    </>
  );
};

export default Notification;
