import useSWRMutation from "swr/mutation";
import { useAPI } from "../useAPI";
import useInfluencersStoreEn from "store/influencersStoreEn";

interface ContractStatusTypeEn {
  status: string;
}

const useContractStatusEn = () => {
  const influencerId = useInfluencersStoreEn(
    (state) => state.currentInfluencer?._id
  );
  
  const { put } = useAPI();

  const updateContractStatus = async (
    url: string,
    { arg }: { arg: ContractStatusTypeEn }
  ) => {
    const response: AuthTokens = await put(`${url}/${arg.status}`, {
      InfluencersId: influencerId,
    });
    return response;
  };

  const { trigger, isMutating, error } = useSWRMutation(
    `/admin/influencer/en/${influencerId}`,
    updateContractStatus
  );

  const updateContractData = async (
    url: string,
    { arg }: { arg: EditUserInformation }
  ) => {
    const response: AuthTokens = await put(url, arg);
    
    return response;
  };

  const {
    trigger: updateContract,
    isMutating: updating,
    error: updateError,
  } = useSWRMutation(
    `/admin/influencer/en/${influencerId}/update`,
    updateContractData
  );

  return {
    onStatusUpdate: trigger,
    updateContract,
    updating,
    isLoading: isMutating,
    error,
    updateError,
  };
};

export default useContractStatusEn;
