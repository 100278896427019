import useSWRMutation from "swr/mutation"
import { useAPI } from "../useAPI"
import useAuthStore from "store/authStore"

const useForgotPassword = () => {
  const userType = useAuthStore((state) => state.userType)
  const { post } = useAPI()

  const sendForgotPasswordRequest = async (
    url: string,
    { arg }: { arg: ForgotPasswordPayload }
  ) => {
    const response: { success: true } = await post(url, arg)
    return response
  }

  const { trigger, isMutating, error } = useSWRMutation(
    `/influencer/en/auth/password/forgot`,
    sendForgotPasswordRequest
  )

  return {
    onForgotPassword: trigger,
    isLoading: isMutating,
    error,
  }
}

export default useForgotPassword
