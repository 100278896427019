import React, { useEffect } from "react";
import ContractorDetailsCard from "common/components/templates/ContractorDetailsCard";
import { useParams } from "react-router-dom";
import useContractorDetails from "common/hooks/useContractorDetails";
import useError from "common/hooks/useErrors";
import useInfluencersStore from "store/influencersStore";
import { getErrorMessage } from "utils/helper";
import { useNavigate } from "react-router-dom";

const ContractorDetails = () => {
  const params = useParams();
  const id = params?.id;
  const navigate = useNavigate();
  const { contractorDetails, error, fetchContractorDetails } = useContractorDetails(id);
  const setError = useError((state) => state.setError);
  const setCurrentInfluencer = useInfluencersStore(
    (state) => state.setCurrentInfluencer
  );
  const clearCurrentInfluencer = useInfluencersStore(
    (state) => state.clearCurrentInfluencer
  );

  useEffect(() => {
    if (error) {
      setError(getErrorMessage(error.subStatusCode));
      navigate("/");
    }
  }, [error, navigate, setError]);

  useEffect(() => {
    if (contractorDetails) {
      setCurrentInfluencer(contractorDetails.data.influencer);
    }
    return () => clearCurrentInfluencer();
  }, [clearCurrentInfluencer, contractorDetails, setCurrentInfluencer]);

  return <ContractorDetailsCard fetchContractorDetails={fetchContractorDetails}/>;
};

export default ContractorDetails;
