import useSWR from "swr"
import { useAPI } from "../useAPI"

interface ContractorResponse {
  success: true
  data: {
    influencer: InfluencerDataEn
  }
}

const useProfile = () => {
  const { get } = useAPI()

  const {
    data: contractorDetails,
    error,
    isLoading,
  } = useSWR<ContractorResponse>(`/influencer/en/profile`, get, {
    revalidateOnFocus: false,
  })

  return {
    contractorDetails,
    isLoading,
    error,
  }
}

export default useProfile
