import React, { Fragment, useRef, useState } from "react"
import Title from "common/components/atoms/Title"
import Input from "common/components/atoms/Input"
import Button from "common/components/atoms/Button"
import Text from "common/components/atoms/Text"
import { useFieldArray, useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { emailRegex, passwordRegex } from "utils/constant"
import styles from "./InfluencerContract.module.scss"
import useError from "common/hooks/useErrors"
import { getErrorMessage } from "utils/helper"
import Loader from "common/components/atoms/Loader"
import Card from "common/components/atoms/Card"
import useAdminInfluencer from "common/hooks/useAdminInfluencer"
import { useNavigate } from "react-router-dom"

const AddInfluencerEn = () => {
  const validationSchema = yup.object().shape({
    fullName: yup.string().required("Full name is required"),
    // state: yup.string().required("State is required"),
    // country: yup.string().required("Country is required"),
    // address: yup.string().required("Address is required"),
    // zipcode: yup.string().required("Zip code is required"),
    // apartment: yup.string(),
    // city: yup.string().required("City is required"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required")
      .matches(emailRegex, "Invalid email format"),
    confirmEmail: yup
      .string()
      .oneOf([yup.ref("email")], "Emails do not match")
      .required("Email confirmation is required"),
    // password: yup
    //   .string()
    //   .required("Password is required")
    //   .matches(passwordRegex, "Invalid password format"),
    mainChannelName: yup.string().required("Main channel name is required"),
    youtubeChannelUrl: yup
      .array()
      .of(
        yup
          .object({ url: yup.string().required("URL is required") })
          .required("At least one YouTube channel URL is required")
      )
      .required("At least one YouTube channel URL is required"),
    channelId: yup.array().of(yup.object({ id: yup.string() })),
    // youtubeShortCount: yup
    //   .number()
    //   .typeError("Please enter a number")
    //   .required("YouTube short count is required")
    //   .positive("Please enter a number greater than 0"),
    // agency: yup.boolean().required("Agency information is required"),
    isCompany: yup.boolean().required("Company information is required"),
    companyName: yup.string().when("isCompany", {
      is: true,
      then: (schema) => schema.required("Company name is required"),
    }),
    // companyState: yup.string().when("isCompany", {
    //   is: true,
    //   then: (schema) => schema.required("State is required"),
    // }),
    // companyCountry: yup.string().when("isCompany", {
    //   is: true,
    //   then: (schema) => schema.required("Country is required"),
    // }),
    // averageViewCounts: yup
    //   .number()
    //   .typeError("Please enter a number")
    //   .required("Average view counts is required")
    //   .positive("Please enter a number greater than 0"),
    // inviteName: yup.string(),
  })

  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const { onAddInfluencerEn } = useAdminInfluencer()

  const [isLoading, setLoading] = useState(false)
  const [isCompanyVisible, setCompnayVisible] = useState(false)
  const setError = useError((state) => state.setError)
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      youtubeChannelUrl: [{ url: undefined }],
      channelId: [{ id: "" }],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "youtubeChannelUrl",
  })

  const {
    fields: channelFields,
    append: channelAppend,
    remove: channelRemove,
  } = useFieldArray({
    control,
    name: "channelId",
  })

  const handleRegister = async (values: any) => {
    try {
      setLoading(true)
      // const filepath: string = await uploadFiletoStorage(selectedFile)
      const prepareData = {
        ...values,
        youtubeChannelUrl: values.youtubeChannelUrl.map(
          (item: { url: any }) => item.url
        ),
        channelId: values?.channelId?.map((item: { id: any }) => item.id) || [],
        companyName: values.isCompany ? values.companyName : "",
        inviteName: values.inviteName?.length ? values.inviteName : null,
        // screenShot: filepath,
      }
      onAddInfluencerEn(prepareData)
        .then((response) => {
          if (response.success) {
            setError("登録が完了しました", "success")
            setSelectedFile(null)
            navigate("/dashboardnew")
          }
        })
        .catch((error: ResponseError) => {
          setError(getErrorMessage(error?.subStatusCode))
        })
        .finally(() => setLoading(false))
    } catch (e) {
      console.log(e)
      setError(getErrorMessage("default"))
    }
    // setUserRegister(prepareData)
    // onProceed()
  }

  return (
    <div className={styles.container}>
      <Card>
        <Title order={6} color="purple" fw="bold">
          インフルエンサーの登録(海外)
        </Title>
        <div className={styles.agencyCheckBox}>
          <Text sz="xxs" fw="bold">
            Do you prefer a corporate contract?
          </Text>
          <input
            type="checkbox"
            {...register("isCompany")}
            onClick={() => setCompnayVisible(!isCompanyVisible)}
          />
          <Text sz="xxs" fw="bold" style={{ width: "auto" }}>
            YES
          </Text>
        </div>
        {isCompanyVisible && (
          <>
            <Input
              label={"Corporate Name"}
              placeholder="Enter corporate name"
              id={"companyName"}
              inputProps={{ ...register("companyName") }}
              error={errors.companyName?.message}
            />
            {/* <Input
              label={"State/province (established by law)"}
              placeholder="Enter your state or province"
              id={"companyState"}
              inputProps={{ ...register("companyState") }}
              error={errors.companyState?.message}
            />
            <Input
              label={"Country (established by law)"}
              placeholder="Enter your Country"
              id={"companyCountry"}
              inputProps={{ ...register("companyCountry") }}
              error={errors.companyCountry?.message}
            /> */}
          </>
        )}
        <Input
          label={"Full Name"}
          placeholder="Enter your Full Name"
          id={"fullName"}
          inputProps={{ ...register("fullName") }}
          error={errors.fullName?.message}
        />
        {/* <Input
          label={"Address"}
          placeholder="Enter your Address"
          id={"address"}
          inputProps={{ ...register("address") }}
          error={errors.address?.message}
        />
        <Input
          label={"Apartment, suite, etc."}
          placeholder="Enter your Apartment, suite, etc."
          id={"apartment"}
          inputProps={{ ...register("apartment") }}
          error={errors.apartment?.message}
        />
        <Input
          label={"City"}
          placeholder="Enter your city"
          id={"city"}
          inputProps={{ ...register("city") }}
          error={errors.city?.message}
        />
        <Input
          label={"State/province"}
          placeholder="Enter your State/province"
          id={"state"}
          inputProps={{ ...register("state") }}
          error={errors.state?.message}
        />
        <Input
          label={"Country"}
          placeholder="Enter your Country"
          id={"country"}
          inputProps={{ ...register("country") }}
          error={errors.country?.message}
        />
        <Input
          label={"ZIP/postal code"}
          placeholder="Enter your ZIP/postal code"
          id={"zipcode"}
          inputProps={{ ...register("zipcode") }}
          error={errors.zipcode?.message}
        /> */}
        <Input
          label={"Email"}
          placeholder="Enter your Email"
          id={"email"}
          inputProps={{ ...register("email") }}
          error={errors.email?.message}
        />
        <Input
          label={"Confirm email"}
          placeholder="Enter your Confirm email"
          id={"confirmEmail"}
          inputProps={{ ...register("confirmEmail") }}
          error={errors.confirmEmail?.message}
        />
        {/* <div style={{ width: "100%" }}>
          <Text sz="xxs" fw="bold">
            Password
          </Text>
          <Text sz="xxs" color="grey" fw="bold">
            This password will be required for invoice verification
          </Text>
          <Text sz="xxs" fw="bold" color="grey">
            ・6 or more characters
          </Text>
          <Text sz="xxs" fw="bold" color="grey">
            ・Upper & lowercase letters
          </Text>
          <Text sz="xxs" fw="bold" color="grey">
            ・At least one number
          </Text>
          <Input
            label={""}
            placeholder="Enter your Password"
            id={"password"}
            inputProps={{ ...register("password") }}
            error={errors.password?.message}
            type="password"
          />
        </div> */}
        <Input
          label={"YouTube channel Name"}
          placeholder=""
          id={"mainChannelName"}
          inputProps={{ ...register("mainChannelName") }}
          error={errors.mainChannelName?.message}
        />
        {fields.map((field, index) => (
          <Fragment key={field.id}>
            <Input
              label={`YouTube channel URL`}
              placeholder={`Enter YouTube channel URL`}
              id={`youtubeChannelUrl.${index}.url`}
              inputProps={{ ...register(`youtubeChannelUrl.${index}.url`) }}
              error={errors.youtubeChannelUrl?.[index]?.url?.message}
            />
            {index !== 0 && (
              <Button variant="purple" onClick={() => remove(index)}>
                - remove URL
              </Button>
            )}
          </Fragment>
        ))}
        <Button variant="purple" onClick={() => append({ url: "" })}>
          + add more URL
        </Button>
        {channelFields?.map(
          (field: { id: React.Key | null | undefined }, index: number) => (
            <Fragment key={field.id}>
              <Input
                label={`Channel ID ${index + 1}`}
                placeholder="Channel ID"
                id={`channelId.${index}.id`}
                inputProps={{ ...register(`channelId.${index}.id`) }}
                error={errors.channelId?.[index]?.id?.message}
              />
              {index !== 0 && (
                <Button variant="purple" onClick={() => channelRemove(index)}>
                  - Remove Channel ID
                </Button>
              )}
            </Fragment>
          )
        )}
        <Button variant="purple" onClick={() => channelAppend({ id: "" })}>
          + Add Channel ID
        </Button>
        {/* <Input
          label={"How often do you post YouTube shorts?"}
          placeholder="YouTube shorts count"
          id={"youtubeShortCount"}
          inputProps={{ ...register("youtubeShortCount") }}
          error={errors.youtubeShortCount?.message}
        />
        <Input
          label={"How many average monthly views do you have?"}
          placeholder="Average monthly views"
          id={"averageViewCounts"}
          inputProps={{ ...register("averageViewCounts") }}
          error={errors.averageViewCounts?.message}
        />
        <Input
          label={
            "Have you been invited by someone? if yes, please tell the YouTube name."
          }
          placeholder=""
          id={"inviteName"}
          inputProps={{ ...register("inviteName") }}
          error={errors.inviteName?.message}
        /> */}
        {/* {!isCompanyVisible && (
          <div className={styles.agencyCheckBox}>
            <Text sz="xxs" fw="bold">
              Are you affiliated with a talent agency?
            </Text>
            <input type="checkbox" {...register("agency")} />
            <Text sz="xxs" fw="bold" style={{ width: "auto" }}>
              YES
            </Text>
          </div>
        )} */}
        <Button
          variant={"yellow"}
          disabled={isLoading}
          onClick={handleSubmit(handleRegister)}
        >
          {isLoading && <Loader />}
          Register
        </Button>
      </Card>
    </div>
  )
}

export default AddInfluencerEn
