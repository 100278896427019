import { AxiosResponse } from "axios";
import useSWRMutation from "swr/mutation";
import { useAPI } from "./useAPI";
import useAuthStore from "store/authStore";

const useChangePassword = () => {
  const userType = useAuthStore((state) => state.userType);
  const { put } = useAPI();

  const sendChangePasswordRequest = async (
    url: string,
    { arg }: { arg: ChangePasswordProps }
  ) => {
    const response: AxiosResponse = await put(url, arg);
    return response;
  };

  const { trigger, isMutating, error } = useSWRMutation(
    `/${userType}/profile/password`,
    sendChangePasswordRequest
  );

  return {
    onChangePassword: trigger,
    isLoading: isMutating,
    error,
  };
};

export default useChangePassword;
