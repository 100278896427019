// Stepper.tsx
import React from "react";
import styles from "./Stepper.module.scss";
import Text from "../Text";

interface StepperProps {
  totalSteps: number;
  currentStep: number;
  title: string;
}

const Stepper: React.FC<StepperProps> = ({
  totalSteps,
  currentStep,
  title,
}) => {
  const progress = currentStep / totalSteps;

  return (
    <div className={styles.stepperWrapper}>
      <div className={styles.stepper}>
        <svg width="50" height="50" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="45" className={styles.circleBack} />
          <circle
            cx="50"
            cy="50"
            r="45"
            className={styles.circleFront}
            strokeDasharray="282.74" // Approximately 2 * PI * r
            strokeDashoffset={`${282.74 * (1 - progress)}`}
          />
        </svg>
        <span
          className={styles.stepNumber}
        >{`${currentStep}/${totalSteps}`}</span>
      </div>
      <Text fw="bold" sz="md" color="purple">
        {`STEP${currentStep} `}
        <Text fw="bold" sz="md" span>
          {title}
        </Text>
      </Text>
    </div>
  );
};

export default Stepper;
