import { create } from "zustand"

interface InfluencersStoreEnState {
  influencers: InfluencerDocsEn | null
  currentInfluencer: InfluencerDataEn | null
  setInfluencersData: (data: InfluencerDocsEn) => void
  setCurrentInfluencer: (data: InfluencerDataEn) => void
  clearCurrentInfluencer: () => void
}

const useInfluencersStoreEn = create<InfluencersStoreEnState>((set) => ({
  influencers: null,
  currentInfluencer: null,

  setInfluencersData: (data) => {
    set({ influencers: data })
  },

  setCurrentInfluencer: (data) => {
    set({ currentInfluencer: data })
  },

  clearCurrentInfluencer: () => {
    set({ currentInfluencer: null })
  },
}))

export default useInfluencersStoreEn
