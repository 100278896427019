import { HTMLAttributes } from 'react'
import styles from './Title.module.scss'

type Color = 'black' | 'blue' | 'blue-light' | 'white' | 'purple'
type Weight = 'normal' | 'bold'
type Align = 'left' | 'right' | 'center' | 'justify'
type FontFamily = 'lato'
type Decoration = 'none' | 'underline' | 'overline' | 'line-through'
type NTitleChild = string | number

interface TitleProps extends HTMLAttributes<HTMLHeadingElement> {
  order?: 1 | 2 | 3 | 4 | 5 | 6
  children: NTitleChild
  color?: Color
  fw?: Weight
  ta?: Align
  td?: Decoration
  fontFamily?: FontFamily
  lineHeight?: string
  className?: string
  style?: any
}

const Title = ({
  order = 1,
  children,
  color = 'black',
  fw = 'normal',
  ta = 'left',
  td = 'none',
  lineHeight = '1.5',
  fontFamily = 'lato',
  className = '',
  style,
}: TitleProps) => {
  const classes = `${styles.title} ${styles[color]} ${styles[fw]} ${
    styles[fontFamily]
  }
  ${styles[ta]} ${styles[td]} ${styles[`h${order}`]} ${className}`
  switch (order) {
    case 1:
      return (
        <h1 className={classes} style={{ lineHeight, ...style }}>
          {children}
        </h1>
      )
    case 2:
      return (
        <h2 className={classes} style={{ lineHeight, ...style }}>
          {children}
        </h2>
      )
    case 3:
      return (
        <h3 className={classes} style={{ lineHeight, ...style }}>
          {children}
        </h3>
      )
    case 4:
      return (
        <h4 className={classes} style={{ lineHeight, ...style }}>
          {children}
        </h4>
      )
    case 5:
      return (
        <h5 className={classes} style={{ lineHeight, ...style }}>
          {children}
        </h5>
      )
    case 6:
      return (
        <h6 className={classes} style={{ lineHeight, ...style }}>
          {children}
        </h6>
      )
    default:
      return (
        <h1 className={classes} style={{ lineHeight, ...style }}>
          {children}
        </h1>
      )
  }
}

export default Title
