import React from "react"
import style from "./accountDetails.module.scss"
import { Title } from "@willeder/component-library"
import Text from "common/components/atoms/Text"
import Button from "common/components/atoms/Button"

const AccountDetail = () => {
  return (
    <div className={style.accountDetailWrapper}>
      <div className={style.accountDetail}>
        <header className={style.header}>
          <Text fw="bold" sz="sm" color="white">
            氏名が入ります。
          </Text>
        </header>
        <div className={style.detail}>
          <div className={style.detailItem}>
            <Text fw="bold" sz="sm">
              氏名
            </Text>
            <div className={style.divider}></div>
            <Text fw="bold" sz="xxs">
              氏名が入ります。
            </Text>
          </div>
          <div className={style.detailItem}>
            <Text fw="bold" sz="sm">
              フリガナ
            </Text>
            <div className={style.divider}></div>
            <Text fw="bold" sz="xxs">
              フリガナが入ります。
            </Text>
          </div>
          <div className={style.detailItem}>
            <Text fw="bold" sz="sm">
              メールアドレス
            </Text>
            <div className={style.divider}></div>
            <Text fw="bold" sz="xxs">
              フリガナが入ります。
            </Text>
          </div>
          <div className={style.detailItem}>
            <Text fw="bold" sz="sm">
              YouTubeチャンネルのURL
            </Text>
            <div className={style.divider}></div>
            <li>
              <a href="">https://www.youtube.com/watch?v=TjPwQlOjzb8</a>
            </li>
            <li>
              <a href="">https://www.youtube.com/watch?v=TjPwQlOjzb8</a>
            </li>
            <li>
              <a href="">https://www.youtube.com/watch?v=TjPwQlOjzb8</a>
            </li>
          </div>
          <div className={style.detailItem}>
            <Text fw="bold" sz="sm">
              YouTubeショートの投稿頻度
            </Text>
            <div className={style.divider}></div>
            <Text fw="bold" sz="xxs">
              投稿頻度が入ります。
            </Text>
          </div>
          <div className={style.detailItem}>
            <Text fw="bold" sz="sm">
              事務所に所属してますか？
            </Text>
            <div className={style.divider}></div>
            <Text fw="bold" sz="xxs">
              はい
            </Text>
          </div>
          <div className={style.detailItem}>
            <Text fw="bold" sz="sm">
              平均再生回数
            </Text>
            <div className={style.divider}></div>
            <Text fw="bold" sz="xxs">
              平均再生回数が入ります
            </Text>
          </div>
          <div className={style.detailItem}>
            <Text fw="bold" sz="sm">
              過去3ヶ月の再生数
            </Text>
            <div className={style.divider}></div>
            <li>
              <a href="">https://www.youtube.com/watch?v=TjPwQlOjzb8</a>
            </li>
            <li>
              <a href="">https://www.youtube.com/watch?v=TjPwQlOjzb8</a>
            </li>
            <li>
              <a href="">https://www.youtube.com/watch?v=TjPwQlOjzb8</a>
            </li>
          </div>
        </div>
      </div>
      <div className={style.buttonContainer}>
        <div className={style.buttonWidth}>
          <Button variant={"yellow"} onClick={() => {}}>
            更新する
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AccountDetail
