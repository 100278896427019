import React, { useEffect, useState } from "react";
import Notification from "common/components/atoms/Notification";
import useError from "common/hooks/useErrors";

const Alert = () => {
  const message = useError((state) => state.error);
  const clearMessage = useError((state) => state.clearError);
  const variant = useError((state) => state.variant);

  const [notificationMessage, setNotificationMessage] = useState<string>("");

  useEffect(() => {
    if (message) {
      setNotificationMessage(message);
    }
  }, [message]);

  return message ? (
    <Notification
      message={notificationMessage}
      onClose={clearMessage}
      timeout={10000}
      variant={variant}
    />
  ) : null;
};

export default Alert;
