import useSWR from "swr"
import axios, { isAxiosError } from "axios"
import { getCookies, removeCookies } from "utils/cookies"
import useError from "./useErrors"
import { getErrorMessage } from "utils/helper"

const usePreviewAdminEnPdf = (documentId?: string | null) => {
  const setError = useError((state) => state.setError)

  const getPdf = async (url: string) => {
    try {
      const accessToken = getCookies("accessToken")
      const headers = {
        Accept: "application/pdf",
        "Content-Type": "application/pdf",
        Authorization: `Bearer ${accessToken}`,
      }
      const document = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/${url}`,
        { responseType: "blob", headers }
      )
      return document.data
    } catch (e: any) {
      if (isAxiosError(e)) {
        if (e.response?.status === 401) {
          removeCookies("accessToken")
          window.location.href = "/login/en"
        }
      }
      setError(getErrorMessage("1021"))
    }
  }

  const {
    data: pdfData,
    error,
    mutate,
    isLoading,
  } = useSWR<any>(
    documentId ? `/admin/influencer/en/${documentId}/document` : "",
    getPdf,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  )

  return {
    pdfData,
    isLoading,
    error,
    mutateInfluencers: mutate,
  }
}

export default usePreviewAdminEnPdf
