import React, { FC, AnchorHTMLAttributes } from "react"
import styles from "./Link.module.scss"

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string
  children: string | JSX.Element
}

const Link: FC<LinkProps> = ({ to, children, ...props }) => {
  return (
    <a href={to} {...props} className={styles.link}>
      {children}
    </a>
  )
}

export default Link
