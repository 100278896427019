import React from "react";
import styles from "./Loader.module.scss";

interface LoaderProps {
  sz?: "sm" | "md" | "lg";
}

const Loader: React.FC<LoaderProps> = ({ sz = "sm" }) => {
  return (
    <div className={styles["loader-container"]}>
      <div className={`${styles["loader"]} ${styles[sz]}`}></div>
    </div>
  );
};

export default Loader;
