import React from "react";
import Card from "../../atoms/Card";
import styles from "./FormLayout.module.scss";
import Stepper from "common/components/atoms/Stepper";

interface formLayout {
  children?: React.ReactNode;
  stepperVisible?: boolean;
  totalSteps?: number;
  currentStep?: number;
  stepTitle?: string;
}

const FormLayout = ({
  children,
  stepperVisible,
  totalSteps = 1,
  currentStep = 1,
  stepTitle = "",
}: formLayout) => {
  return (
    <div className={styles["form-layout"]}>
      {stepperVisible && (
        <Stepper
          totalSteps={totalSteps}
          currentStep={currentStep}
          title={stepTitle}
        />
      )}
      <Card>{children}</Card>
    </div>
  );
};

export default FormLayout;
