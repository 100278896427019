import React from "react"
import styles from "../Navbar/Navbar.module.scss"
import { ReactComponent as DropdownArrow } from "../../../assets/icons/dropdown.svg"
import { ReactComponent as MobileDropdownArrow } from "../../../assets/icons/dropdown-light.svg"
import { removeCookies } from "utils/cookies"
import useAuthStore from "store/authStore"
import { useAPI } from "common/hooks/useAPI"
import { useNavigate } from "react-router-dom"
import useInfluencersStoreEn from "store/influencersStoreEn"

const Navbar = () => {
  const { logout, userType } = useAuthStore((state) => state)
  const { put } = useAPI()
  const currentInfluencer = useInfluencersStoreEn(
    (state) => state.currentInfluencer
  )

  const navigate = useNavigate()
  const menuTitle = `${currentInfluencer?.fullName || ""}`

  const onLogout = () => {
    put(`/influencer/en/auth/logout`).then(() => {
      removeCookies("accessToken")
      removeCookies("refreshToken")
      logout()
      navigate("/en/login")
    })
  }

  return (
    <nav className={styles.nav}>
      <h3 onClick={() => navigate("/en/")}>Orinas Contract Tool</h3>
      <menu className={styles.menu}>
        {menuTitle}
        <DropdownArrow />
      </menu>
      <menu className={styles.mobileMenu}>
        <div>
          <h3>{menuTitle}</h3>
          <MobileDropdownArrow />
        </div>
      </menu>
      <ul className={styles.dropdownMenu}>
        {userType !== "admin" && (
          <>
            <li onClick={() => navigate("/en/edit-profile")}>Edit Profile</li>
            <li onClick={() => navigate("/en/change-password")}>
              Change Password
            </li>
          </>
        )}
        <li onClick={onLogout}>Logout</li>
      </ul>
    </nav>
  )
}

export default Navbar
