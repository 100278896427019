import React, { Fragment, useEffect, useState } from "react"
import styles from "./UserEditModal.module.scss"
import Modal from "common/components/atoms/Modal"
import Title from "common/components/atoms/Title"
import Dropdown from "common/components/atoms/Dropdown"
import Input from "common/components/atoms/Input"
import Button from "common/components/atoms/Button"
import Text from "common/components/atoms/Text"
import useInfluencersStore from "store/influencersStore"
import useContractStatus from "common/hooks/useContractStatus"
import useError from "common/hooks/useErrors"
import { contractStatus } from "utils/constant"
import Loader from "common/components/atoms/Loader"
import { useFieldArray, useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { emailRegex } from "utils/constant"
import { getAddressbyPostalCode, getErrorMessage } from "utils/helper"

interface UserEditModalProps {
  isEditModalOpen: boolean
  closeEditModal: () => void
  fetchContractorDetails: () => void
}

const UserEditModal: React.FC<UserEditModalProps> = ({
  isEditModalOpen,
  closeEditModal,
  fetchContractorDetails,
}) => {
  const validationSchema = yup.object().shape({
    status: yup.string(),
    documentId: yup
      .number()
      .typeError("数字を入力してください")
      // .positive("0より大きい数字を入力してください")
      .transform((value, originalValue) => {
        if (originalValue === undefined || originalValue === "") {
          return undefined
        }
        return value
      })
      .nullable(),
    familyName: yup.string(),
    firstName: yup.string(),
    familyNameKana: yup.string(),
    firstNameKana: yup.string(),
    zipcode: yup
      .string()
      .test("valid-length", getErrorMessage("1014"), (value) =>
        value ? value.length === 7 : true
      ),
    province: yup.string(),
    buildingName: yup
      .string()
      .nullable()
      .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
    address: yup.string(),
    city: yup.string(),
    email: yup
      .string()
      .email(getErrorMessage("1006"))
      .required(getErrorMessage("1005"))
      .matches(emailRegex, getErrorMessage("1006")),
    mainChannelName: yup.string(),
    youtubeChannelUrl: yup
      .array()
      .of(
        yup
          .object({ url: yup.string() })
          .required("YouTubeチャンネルのURLを最低1ついれてください。")
      )
      .required("YouTubeチャンネルのURLを最低1ついれてください。"),
    channelId: yup.array().of(yup.object({ id: yup.string() })),
    youtubeShortCount: yup.number().typeError("数字を入力してください"),
    agency: yup.boolean(),
    isCompany: yup.boolean(),
    companyName: yup.string().when("isCompany", {
      is: true,
      then: (schema) => schema,
    }),
    averageViewCounts: yup.number().typeError("数字を入力してください"),
    invoiceNo: yup.string().typeError("番号を入力してください"),
    currentBalance: yup
      .number()
      .min(0, "0以上の数字をいれてください")
      // .positive("必須エラーメッセージ")
      .typeError("数字を入力してください"),
    bankNo: yup.string(),
    bankAccountHolder: yup.string(),
    bankName: yup.string(),
    bankBranch: yup.string(),
    bankType: yup.string(),
    inviteName: yup.string(),
  })

  const contractorDetails = useInfluencersStore(
    (state) => state.currentInfluencer
  )
  const { updateContract, updating } = useContractStatus()
  const setError = useError((state) => state.setError)

  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false)
  const [editData, setEditData] = useState<EditUserInformation>()
  const [isCompanyVisible, setCompanyVisible] = useState(false)

  const contractStatusArray = Object.entries(contractStatus).map(
    ([key, value]) => ({
      value: key,
      label: value,
    })
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      channelId: [{ id: "" }],
      buildingName: "",
    },
  })

  useEffect(() => {
    if (contractorDetails) {
      setValue("status", contractorDetails?.status)
      setValue("documentId", contractorDetails?.documentId || undefined)
      setValue("familyName", contractorDetails?.familyName)
      setValue("firstName", contractorDetails?.firstName)
      setValue("familyNameKana", contractorDetails?.familyNameKana)
      setValue("firstNameKana", contractorDetails?.firstNameKana)
      setValue("province", contractorDetails?.province)
      setValue("buildingName", contractorDetails?.buildingName)
      setValue("address", contractorDetails?.address)
      setValue("zipcode", contractorDetails?.zipcode)
      setValue("city", contractorDetails?.city)
      setValue("email", contractorDetails?.email)
      setValue("mainChannelName", contractorDetails?.mainChannelName)
      setValue(
        "youtubeChannelUrl",
        contractorDetails?.youtubeChannelUrl.length
          ? contractorDetails?.youtubeChannelUrl.map((url) => ({ url }))
          : [{ url: undefined }]
      )
      setValue(
        "channelId",
        contractorDetails?.channelId?.length
          ? contractorDetails?.channelId?.map((id) => ({ id }))
          : [{ id: "" }]
      )
      setValue("youtubeShortCount", contractorDetails?.youtubeShortCount)
      setValue("agency", contractorDetails?.agency)
      setValue("isCompany", contractorDetails?.isCompany)
      setValue(
        "companyName",
        contractorDetails?.isCompany ? contractorDetails?.companyName : ""
      )
      setValue("averageViewCounts", contractorDetails?.averageViewCounts)
      setValue("inviteName", contractorDetails?.inviteName || undefined)
      setValue("currentBalance", contractorDetails?.currentBalance)
      setValue("bankNo", contractorDetails?.bankNo)
      setValue("bankAccountHolder", contractorDetails?.bankAccountHolder)
      setValue("bankName", contractorDetails?.bankName)
      setValue("bankBranch", contractorDetails?.bankBranch)
      setValue("bankType", contractorDetails?.bankType)
      setValue("invoiceNo", contractorDetails?.invoiceNo)
    }
  }, [contractorDetails])

  const { fields, append, remove } = useFieldArray({
    control,
    name: "youtubeChannelUrl",
  })

  const {
    fields: channelFields,
    append: channelAppend,
    remove: channelRemove,
  } = useFieldArray({
    control,
    name: "channelId",
  })

  const handleUpdate = (values: any) => {
    const prepareData = {
      ...values,
      youtubeChannelUrl: values?.youtubeChannelUrl?.map(
        (item: { url: any }) => item.url
      ),
      channelId: values?.channelId?.map((item: { id: any }) => item.id) || [],
      companyName: values?.isCompany ? values.companyName : "",
      inviteName: values?.inviteName?.length ? values?.inviteName : null,
    }
    setEditData(prepareData)
    setConfirmModalOpen(true)
  }

  const updateUserContract = () => {
    try {
      if (editData) {
        updateContract(editData)
          .then(() => setError("更新しました", "success"))
          .catch((error) => setError(getErrorMessage(error.subStatusCode)))
          .finally(() => {
            fetchContractorDetails()
            setConfirmModalOpen(false)
            closeEditModal()
          })
      }
    } catch (e) {
      setError(getErrorMessage("default"))
    }
  }

  const getAddress = (address: AddressResponseData) => {
    if (address.area) setValue("address", address.area)
    if (address.city) setValue("city", address.city)
    if (address.province) setValue("province", address.province)
    if (address.street) setValue("address", address.street)
  }

  return (
    <>
      <Modal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        key={"edit-modal"}
      >
        <div className={styles.userEditModal}>
          <Title order={5} color="purple" fw="bold">
            編集
          </Title>
          <Dropdown
            options={contractStatusArray}
            label={"ステータス"}
            id={"status"}
            name="status"
            inputProps={{ ...register("status") }}
            error={errors.status?.message}
          />
          <Input
            label={"ドキュメントID"}
            placeholder="ドキュメントIDを入力してください"
            id={"documentId"}
            name="documentId"
            inputProps={{ ...register("documentId") }}
            error={errors.documentId?.message}
          />
          <div className={styles.agencyCheckBox}>
            <Text fw="bold">法人ですか？</Text>
            <input
              type="checkbox"
              {...register("isCompany")}
              onClick={() => setCompanyVisible(!isCompanyVisible)}
            />
          </div>
          {isCompanyVisible && (
            <Input
              label={"法人名"}
              placeholder="法人名を入力してください"
              id={"companyName"}
              inputProps={{ ...register("companyName") }}
              error={errors.companyName?.message}
            />
          )}
          <Input
            label={"苗字"}
            placeholder="苗字を入力してください"
            id={"familyName"}
            inputProps={{ ...register("familyName") }}
            error={errors.familyName?.message}
          />
          <Input
            label={"名前"}
            placeholder="名前を入力してください"
            id={"firstName"}
            inputProps={{ ...register("firstName") }}
            error={errors.firstName?.message}
          />
          <Input
            label={"苗字（カタカナ）"}
            placeholder="苗字（カタカナ）を入力してください"
            id={"familyNameKana"}
            inputProps={{ ...register("familyNameKana") }}
            error={errors.familyNameKana?.message}
          />
          <Input
            label={"名前（カタカナ）"}
            placeholder="名前（カタカナ）を入力してください"
            id={"firstNameKana"}
            inputProps={{ ...register("firstNameKana") }}
            error={errors.firstNameKana?.message}
          />
          <Input
            label={"郵便番号(ハイフン不要)"}
            placeholder="郵便番号を入力してください"
            id={"zipcode"}
            inputProps={{ ...register("zipcode") }}
            error={errors.zipcode?.message}
          />
          <Button
            variant="purple"
            onClick={() => {
              const postalCode = getValues("zipcode")
              if (postalCode && !errors.zipcode) {
                getAddressbyPostalCode(postalCode, getAddress)
              }
            }}
          >
            住所を検索する
          </Button>
          <Input
            label={"都道府県"}
            placeholder="都道府県を入力してください"
            id={"province"}
            inputProps={{ ...register("province") }}
            error={errors.province?.message}
          />
          <Input
            label={"市区町村"}
            placeholder="市区町村を入力してください"
            id={"city"}
            inputProps={{ ...register("city") }}
            error={errors.city?.message}
          />
          <Input
            label={"番地"}
            placeholder="番地を入力してください"
            id={"address"}
            inputProps={{ ...register("address") }}
            error={errors.address?.message}
          />
          <Input
            label={"ビル名"}
            placeholder="ビル名を入力してください"
            id={"buildingName"}
            inputProps={{ ...register("buildingName") }}
            error={errors.buildingName?.message}
          />
          <Input
            label={"メールアドレス"}
            placeholder="メールアドレスを入力してください"
            id={"email"}
            inputProps={{ ...register("email") }}
            type="email"
            error={errors.email?.message}
            autoComplete="off"
          />

          <Input
            label={"メインチャンネルの名前"}
            placeholder="メインチャンネルの名前を入力してください"
            id={"mainChannelName"}
            inputProps={{ ...register("mainChannelName") }}
            error={errors.mainChannelName?.message}
          />
          {fields.map((field, index) => (
            <Fragment key={field.id}>
              <Input
                label={`YouTubeチャンネルのURL ${index + 1}`}
                placeholder="YouTubeチャンネルのURLを入力してください"
                id={`youtubeChannelUrl.${index}.url`}
                inputProps={{ ...register(`youtubeChannelUrl.${index}.url`) }}
                error={errors.youtubeChannelUrl?.[index]?.url?.message}
              />
              {index !== 0 && (
                <Button variant="purple" onClick={() => remove(index)}>
                  - URLを削除する
                </Button>
              )}
            </Fragment>
          ))}
          <Button variant="purple" onClick={() => append({ url: "" })}>
            + URLを追加する
          </Button>
          {channelFields?.map(
            (field: { id: React.Key | null | undefined }, index: number) => (
              <Fragment key={field.id}>
                <Input
                  label={`チャンネルID ${index + 1}`}
                  placeholder="チャンネルID"
                  id={`channelId.${index}.id`}
                  inputProps={{ ...register(`channelId.${index}.id`) }}
                  error={errors.channelId?.[index]?.id?.message}
                />
                {index !== 0 && (
                  <Button variant="purple" onClick={() => channelRemove(index)}>
                    - チャンネルIDを削除する
                  </Button>
                )}
              </Fragment>
            )
          )}
          <Button variant="purple" onClick={() => channelAppend({ id: "" })}>
            + チャンネルIDを追加する
          </Button>
          <Input
            label={"YouTubeショートの投稿頻度(月)"}
            placeholder="YouTubeショートの投稿頻度を入力してください"
            id={"youtubeShortCount"}
            inputProps={{ ...register("youtubeShortCount") }}
            error={errors.youtubeShortCount?.message}
          />
          <Input
            label={"紹介者の名前"}
            placeholder="紹介者の名前を入力してください"
            id={"inviteName"}
            inputProps={{ ...register("inviteName") }}
            error={errors.inviteName?.message}
          />
          <div className={styles.agencyCheckBox}>
            <Text fw="bold">事務所に所属してますか？</Text>
            <input type="checkbox" {...register("agency")} />
          </div>
          <Input
            label={"平均再生回数(月)"}
            placeholder="平均再生回数を入力してください"
            id={"averageViewCounts"}
            inputProps={{ ...register("averageViewCounts") }}
            error={errors.averageViewCounts?.message}
          />
          <Input
            label={"インボイス番号(任意)"}
            placeholder="インボイス番号を入力してください"
            id={"invoiceNo"}
            inputProps={{ ...register("invoiceNo") }}
            error={errors.invoiceNo?.message}
          />
          <Input
            label={"売上金"}
            placeholder="売上金を入力してください"
            id={"currentBalance"}
            inputProps={{ ...register("currentBalance") }}
            error={errors.currentBalance?.message}
          />
          <div className={styles.mainTitle}>
            <Text fw="bold">口座情報</Text>
          </div>
          <Input
            label={"口座番号"}
            placeholder="口座番号を入力してください"
            id={"bankNo"}
            inputProps={{ ...register("bankNo") }}
            error={errors.bankNo?.message}
          />
          <Input
            label={"登録名"}
            placeholder="登録名を入力してください"
            id={"bankAccountHolder"}
            inputProps={{ ...register("bankAccountHolder") }}
            error={errors.bankAccountHolder?.message}
          />
          <Input
            label={"銀行名"}
            placeholder="銀行名を入力してください"
            id={"bankName"}
            inputProps={{ ...register("bankName") }}
            error={errors.bankName?.message}
          />
          <Input
            label={"支店名"}
            placeholder="支店名を入力してください"
            id={"bankBranch"}
            inputProps={{ ...register("bankBranch") }}
            error={errors.bankBranch?.message}
          />
          <Input
            label={"口座タイプ"}
            placeholder="口座タイプを入力してください"
            id={"bankType"}
            inputProps={{ ...register("bankType") }}
            error={errors.bankType?.message}
          />
          <Button variant={"yellow"} onClick={handleSubmit(handleUpdate)}>
            更新する
          </Button>
          <Button variant={"purple"} onClick={closeEditModal}>
            キャンセルして戻る
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={isConfirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        key={"confirm-modal"}
      >
        <div className={styles.userEditModal}>
          <Text ta="center">更新してよろしいでしょうか？</Text>
          <Button
            variant={"yellow"}
            disabled={updating}
            onClick={updateUserContract}
          >
            {updating && <Loader />} 更新する
          </Button>
          <Button variant={"purple"} onClick={() => setConfirmModalOpen(false)}>
            キャンセルして戻る
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default UserEditModal
