import useSWRMutation from "swr/mutation"
import { useAPI } from "../useAPI"

const useResetPassword = () => {
  const { put } = useAPI()

  const sendRestPasswordRequest = async (
    url: string,
    { arg }: { arg: ResetPasswordPayload }
  ) => {
    const response: { success: boolean } = await put(url, arg)
    return response
  }

  const { trigger, isMutating, error } = useSWRMutation(
    `${process.env.REACT_APP_API_BASE_URL}/influencer/en/auth/password/reset`,
    sendRestPasswordRequest
  )

  return {
    onResetPassword: trigger,
    isLoading: isMutating,
    error,
  }
}

export default useResetPassword
