import useSWRMutation from "swr/mutation"
import { useAPI } from "./useAPI"

const useAdminInfluencer = () => {
  const { post } = useAPI()

  const sendRegisterRequest = async (
    url: string,
    { arg }: { arg: RegisterProps }
  ) => {
    const response: { success: boolean } = await post(url, arg)
    return response
  }

  const {
    trigger: onAddInfluencer,
    isMutating: isLoading,
    error,
  } = useSWRMutation(`/admin/influencer/create`, sendRegisterRequest)

  const {
    trigger: onAddInfluencerEn,
    isMutating: isAddingInfluencer,
    error: addError,
  } = useSWRMutation(`/admin/influencer/en/create`, sendRegisterRequest)

  return {
    onAddInfluencer,
    isLoading,
    error,
    onAddInfluencerEn,
    isAddingInfluencer,
    addError,
  }
}

export default useAdminInfluencer
