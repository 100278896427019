import React, { useEffect } from "react";
import InfluencersTableSection from "common/components/templates/InfluencersTableSection";
import useInfluencers from "common/hooks/useInfluencers";
import useInfluencersStore from "store/influencersStore";
import useError from "common/hooks/useErrors";
import useAuthStore from "store/authStore";
import InfluencerDashboard from "common/components/templates/InfluencerDashboard";
import Button from "common/components/atoms/Button";
import styles from "./Dashboard.module.scss";
import { getInfluencersCSVdata } from "services/influencerReports";
import { downloadCSVfile, getErrorMessage } from "utils/helper";
import Loader from "common/components/atoms/Loader";

const Dashboard = () => {
  const { influencersData, isLoading, error } = useInfluencers();
  const setInfluencersData = useInfluencersStore(
    (state) => state.setInfluencersData
  );
  const setError = useError((state) => state.setError);
  const userType = useAuthStore((state) => state.userType);

  useEffect(() => {
    if (influencersData) {
      setInfluencersData(influencersData.data.influencer);
    }
  }, [influencersData, setInfluencersData]);

  useEffect(() => {
    if (error) setError(error);
  }, [error, setError]);

  const downloadDataCSV = () => {
    getInfluencersCSVdata()
      .then((response) => {
        downloadCSVfile(response);
      })
      .catch(() => setError(getErrorMessage("default")));
  };

  return userType === "admin" ? (
    <div className={styles.adminDashboard}>
      <Button maxWidth={236} variant="yellow" onClick={downloadDataCSV}>
        登録者一覧を出力
      </Button>
      {isLoading ? <Loader sz="lg" /> : <InfluencersTableSection />}
    </div>
  ) : (
    <InfluencerDashboard />
  );
};

export default Dashboard;
