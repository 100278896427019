import useSWR from "swr";
import { useAPI } from "./useAPI";

interface ContractorResponse {
  success: true;
  data: {
    influencer: InfluencerData;
  };
}

const useContractorDetails = (influencerId?: string) => {
  const { get } = useAPI();

  const {
    data: contractorDetails,
    mutate: fetchContractorDetails,
    error,
    isLoading,
  } = useSWR<ContractorResponse>(
    influencerId ? `/admin/influencer/${influencerId}/getOne` : null,
    get,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    contractorDetails,
    fetchContractorDetails,
    isLoading,
    error,
  };
};

export default useContractorDetails;
