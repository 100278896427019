import useSWRMutation from "swr/mutation"
import { useAPI } from "../useAPI"

const useEditProfile = () => {
  const { put } = useAPI()

  const sendUpdateRequest = async (
    url: string,
    { arg }: { arg: Partial<ENRegisterProps> }
  ) => {
    const response: AuthTokens = await put(url, arg)
    return response
  }

  const { trigger, isMutating, error } = useSWRMutation(
    `/influencer/en/profile/update`,
    sendUpdateRequest
  )

  return {
    onUpdateProfile: trigger,
    isLoading: isMutating,
    error,
  }
}

export default useEditProfile
