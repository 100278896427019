import { create } from "zustand"

interface InfluencersStoreState {
  influencers: InfluencerDocs | null
  currentInfluencer: InfluencerData | null
  setInfluencersData: (data: InfluencerDocs) => void
  setCurrentInfluencer: (data: InfluencerData) => void
  clearCurrentInfluencer: () => void
}

const useInfluencersStore = create<InfluencersStoreState>((set) => ({
  influencers: null,
  currentInfluencer: null,

  setInfluencersData: (data) => {
    set({ influencers: data })
  },

  setCurrentInfluencer: (data) => {
    set({ currentInfluencer: data })
  },

  clearCurrentInfluencer: () => {
    set({ currentInfluencer: null })
  },
}))

export default useInfluencersStore
