import useSWR from "swr"
import { useAPI } from "../useAPI"

interface ContractorResponse {
  success: true
  data: {
    influencer: InfluencerDataEn
  }
}

const useContractorDetails = (influencerId?: string) => {
  const { get } = useAPI()

  const {
    data: contractorDetails,
    mutate: fetchContractorDetails,
    error,
    isLoading,
  } = useSWR<ContractorResponse>(
    influencerId ? `/admin/influencer/en/${influencerId}/getOne` : null,
    get,
    {
      revalidateOnFocus: false,
    }
  )

  return {
    contractorDetails,
    fetchContractorDetails,
    isLoading,
    error,
  }
}

export default useContractorDetails
