import useSWR from "swr";
import { useAPI } from "./useAPI";
import useAuthStore from "store/authStore";

interface ContractorResponse {
  success: true;
  data: {
    influencer: InfluencerData;
  };
}

const useProfile = () => {
  const { get } = useAPI();
  const userType = useAuthStore((state) => state.userType);

  const {
    data: contractorDetails,
    error,
    isLoading,
  } = useSWR<ContractorResponse>(
    userType === "influencer" ? `/influencer/profile` : "",
    get,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    contractorDetails,
    isLoading,
    error,
  };
};

export default useProfile;
