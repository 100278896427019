import { AxiosResponse } from "axios";
import useSWRMutation from "swr/mutation";
import { useAPI } from "./useAPI";
import useAuthStore from "store/authStore";

const useResetPassword = () => {
  const userType = useAuthStore((state) => state.userType);
  const setLoading = useAuthStore((state) => state.setLoading);
  const { put } = useAPI();

  const sendRestPasswordRequest = async (
    url: string,
    { arg }: { arg: ResetPasswordPayload }
  ) => {
    const response: { success: boolean } = await put(url, arg);
    return response;
  };

  const { trigger, isMutating, error } = useSWRMutation(
    `${process.env.REACT_APP_API_BASE_URL}/${userType}/auth/password/reset`,
    sendRestPasswordRequest
  );

  setLoading(isMutating);

  return {
    onResetPassword: trigger,
    isLoading: isMutating,
    error,
  };
};

export default useResetPassword;
